import './App.css';
import logo from './images/word999logo.png';
import screenshot from './images/screenshot-windows.png';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import twitter from './images/twitter.png';
function App() {
  return (
    <div className="App">
<>
  <header>
    <div className="container-new py-6 px-3 text-center">
      <a href="https://word999.com/">
        <img
          src={logo}
          alt="单词999"
          width={250}
          height={88}
        />
      </a>
    </div>
  </header>
  <main>
    <div className="hero text-center">
      <div className="container-new px-3">
        <div className="pb-6">
          {/* <h1 class="f00-light text-white">单词999</h1> */}
          <p className="col-md-8 mx-auto mb-4 f3-light">
            不是你背不过英文单词，只是因为你没有找到合适的工具，像玩游戏一样背单词，英语学习神器！赶快下载吧！
          </p>

          <a
            className="mx-1 my-3 f3 btn btn-large btn-desktop-purple"
            href="https://fast.daboluo.net/word999/word999_windows.zip"
            data-for-os="windows"
            data-download="windows"
          >
            Download for Windows (64bit)
          </a>
          <p>Free to download.</p>
        </div>
        <div
          className="d-block width-fit mx-auto mb-6"
          style={{ marginTop: 40 }}
          id="main-screenshots"
        >
          <picture data-for-os="windows">
            <img src={screenshot} alt="" />
          </picture>
        </div>
      </div>
    </div>
    {/* Features */}
    <div
      style={{ maxWidth: 900 }}
      className="text-center container-new feature"
    >
      <div className="container-new d-md-flex flex-items-center">
        <div className="flex-auto text-md-right col-md-4 p-5">
          <img src={image1} alt="" style={{ maxWidth: 314 }} />
        </div>
        <div className="flex-auto text-md-left col-md-4 p-5">
          <h2 className="f3-light text-white">游戏化设计</h2>
          <p className="f4-light mt-2">
            我们的设计理念是让用户像玩游戏一样轻松记忆英语单词，用心做一款好玩，有趣，并且有用的英语软件。
          </p>
        </div>
      </div>
      <div className="container-new d-md-flex flex-items-center flex-md-row-reverse mb-6 feature">
        <div className="flex-auto text-md-left col-md-4 p-5 mx-md-auto">
          <img src={image2} alt="" style={{ maxWidth: 324 }} />
        </div>
        <div className="flex-auto text-md-left col-md-4 p-5">
          <h2 className="f3-light text-white">自定义单词库</h2>
          <p className="f4-light mt-2">
            我们在记忆英语单词的过程中，会有很多单词需要拿出来专门训练，才能够掌握和熟悉，您可以自定义生词库，针对性训练！
          </p>
        </div>
      </div>
      <div className="container-new d-md-flex flex-items-center mb-6 feature">
        <div className="flex-auto text-md-right col-md-4 p-5 mx-md-auto">
          <img style={{ maxWidth: 325 }} src={image3} alt="" />
        </div>
        <div className="flex-auto text-md-left col-md-4 p-5">
          <h2 className="f3-light text-white">愉快的手感</h2>
          <p className="f4-light mt-2">
            输入单词的感觉，就像一台老式的英文打字机，你能感觉到节奏，即时反馈，奖励，像刷短视频一样让你停不下来，赶快下载体验吧.
          </p>
        </div>
      </div>
    </div>
  </main>
  <hr className="my-6 my-md-10 mx-auto col-md-1" />
  <div className="col-md-6 mx-auto text-center text-gray-lighter">
    <p>
      <a href="https://twitter.com/daboluo_net" className="mr-4">
        <img
          src={twitter}
          alt="Icon representing organizations"
          className="width-fit flex-auto"
        />
      </a>
      
      <a href="https://ko-fi.com/T6T2XUERH">
      <img
        height={36}
        style={{ border: 0, height: 36 }}
        src="https://storage.ko-fi.com/cdn/kofi1.png?v=3"
        border={0}
        alt="Buy Me a Coffee at ko-fi.com"
      />
    </a>
    </p>
  </div>
  <footer className="mb-6 px-3 text-center alt-text-small">
    <p className="copyright">© 2024 Word999.com All rights reserved.</p>
  </footer>
</>

    </div>
  );
}
export default App;
